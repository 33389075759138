<template>
        <v-dialog v-model="newPersonDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="grey lighten-2">
                <v-toolbar color="#99151C" dark>
                    <v-btn icon dark @click.native="$refs.form.reset(), $emit('update:newPersonDialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Person hinzufügen</v-toolbar-title>
                </v-toolbar>
                <v-dialog v-model="newFirmaDialog" persistent max-width="700">
                        <add-firma :firmen.sync="firmen" :dialog.sync="newFirmaDialog" :aktion="aktionen" :laender="laender" :kategorien="kategorien"> </add-firma>
                </v-dialog>
                <v-container class="pa-5" >
                    <v-card class="pa-4 elevation-3">
                        <h1>Persönliche Daten</h1> <v-btn @click="newKontakt = !newKontakt" v-if="!newKontakt" color="accent">
                            Kontaktdaten hinzufügen<v-icon dark>mdi-add</v-icon>
                        </v-btn>
                        <v-btn @click="newKontakt = !newKontakt" v-else color="accent">
                            Kontaktdaten löschen<v-icon dark>mdi-clear</v-icon>
                        </v-btn>
                        <v-btn @click="privatAdresse = !privatAdresse" class="ml-3" v-if="!privatAdresse" color="accent">
                            Private Adresse hinzufügen<v-icon dark>mdi-add</v-icon>
                        </v-btn>
                        <v-btn @click="privatAdresse = !privatAdresse" class="ml-3" v-else color="accent">
                            Keine Private Adresse<v-icon dark>mdi-clear</v-icon>
                        </v-btn>
                        <v-form ref="form" v-model="valid" autocomplete="off">
                          <v-row>
                            <v-col cols="2">
                              <v-select :items="anreden"
                                        label="Anrede"
                                        required
                                        :rules="rules.anrede"
                                        color="black"
                                        v-model="person.anrede"></v-select>
                            </v-col>

                            <v-col cols="2" >
                              <v-text-field v-model="person.titel"
                                            color="black"
                                            label="Titel"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field v-model="person.vorname"
                                            required
                                            :rules="rules.vorname"
                                            color="black"
                                            label="Vorname"></v-text-field>
                            </v-col>
                            <v-col cols="2" >
                              <v-text-field v-model="person.nachname"
                                            :rules="rules.nachname"
                                            color="black"
                                            label="Nachname"></v-text-field>
                              <check-person-name :items="personen" :input.sync="person.nachname"/>
                            </v-col>

                            <v-col cols="2">
                              <v-text-field v-model="person.nameZusatz"
                                            color="black"
                                            label="Name Zusatz"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field v-model="person.position"
                                            color="black"
                                            label="Position"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-menu ref="menu"
                                      :close-on-content-click="false"
                                      v-model="dateMenu"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px">
                                <template v-slot:activator="{ attr, on}">
                                <v-text-field v-model="person.geburtstag"
                                              label="Geburtstag"
                                              color="black"
                                              v-on="on"
                                              v-bind="attr"
                                              prepend-icon="mdi-calendar"
                                              readonly></v-text-field>
                                </template>
                                <v-date-picker ref="picker"
                                               v-model="person.geburtstag"
                                               :max="new Date().toISOString().substr(0, 10)"
                                               min="1900-01-01" dark locale="de-de"
                                               @change="save(person.geburtstag)"></v-date-picker>
                              </v-menu>

                            </v-col>
                            <v-col cols="2">
                              <v-text-field v-model="person.kontaktvon"
                                            required
                                            :rules="rules.kontakt"
                                            color="black"
                                            label="Kontakt von"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field v-model="person.bemerkung"
                                            color="black"
                                            label="Bemerkung"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row  v-if="newKontakt">
                            <v-col cols="12" md="2">
                              <v-text-field v-model="person.email"
                                            color="black"
                                            label="E-mail"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field v-model="person.telefon"
                                            color="black"
                                            label="Telefon"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field v-model="person.mobil"
                                            color="black"
                                            label="Mobile"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-if="privatAdresse">
                              <v-col cols="12" md="2" >
                                <v-text-field v-model="person.straße"
                                              color="black"
                                              label="Straße (privat)"></v-text-field>
                              </v-col>
                            <v-col cols="12" md="2" >
                              <v-text-field v-model="person.plz"
                                            :rules="rules.plz"
                                            color="black"
                                            label="Plz (privat)"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" >
                              <v-text-field v-model="person.ort"
                                            color="black"
                                            label="Ort (privat)"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" >
                              <v-autocomplete v-model="person.land.id"
                                              :items="laender"
                                              item-text="name"
                                              item-value="id"
                                              label="Land (privat)"
                                              color="black"
                                              persistent-hint>
                                <v-slide-x-reverse-transition slot="append-outer"
                                                              mode="out-in">
                                </v-slide-x-reverse-transition>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                            <v-divider></v-divider>
                            <h1>Firma</h1>
                          <v-row>
                            <v-col md="3" cols="12">
                              <v-autocomplete v-model="person.firma.id"
                                              :items="firmen"
                                              item-text="name"
                                              item-value="id"
                                              label="Firma"
                                              :rules="rules.firma"
                                              persistent-hint color="black">
                                <template v-slot:item="data">
                                  <v-list  v-bind="data.attrs" v-on="data.on">
                                  <v-list-item two-line >
                                    <v-list-item-content>
                                      <v-list-item-title v-text="data.item.name"></v-list-item-title>
                                      <v-list-item-subtitle>{{data.item.straße}} {{data.item.plz}} {{data.item.ort}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  </v-list>
                                  </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col md="2" cols="6">
                              <v-btn @click="newFirmaDialog = true" color="accent" v-if="!newFirma">
                                Neue Firma hinzufügen <v-icon dark>mdi-add</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                            <v-btn color="accent" @click="createNewPerson">Speichern</v-btn>
                        </v-form>
                    </v-card>
                </v-container>
            </v-card>
        </v-dialog>
</template>

<script>
      import {  mapState } from 'vuex';
    export default {
        components: {
          'check-person-name': () => import('../sub-components/check-person-name')
        },
        watch: {
            dateMenu(val) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
        },
        data() {
            return {
                anreden: ['Herr', 'Frau', 'K/A'],
                newKontakt: false,
                privatAdresse: false,
                newFirma: false,
                newFirmaDialog: false,
                valid: false,
                dateMenu: false,
                rules: {
                    vorname: [
                        v => !!v || 'Bitte Vornamen eintragen'
                    ],
                    nachname: [
                        v => !!v || 'Bitte Nachnamen eintragen'
                    ],
                    anrede: [
                        v => !!v || 'Bitte Anrede eintragen'
                    ],
                    kontakt: [
                        v => !!v || 'Bitte Kontakt von eintragen'
                    ],
                  firma: [
                    v => !!v || 'Bitte Firma oder K/A eintragen'
                  ],
                },
                person: {
                    firma: {},
                    land: {},
                    anrede: null,
                    bemerkung: null,
                    created: null,
                    email: null,
                    geburtstag: null,
                    kontaktVon: null, mobil: null, nachname: null,
                    namenZusatz: null,
                    ort: null,
                    plz: null,
                    position: null,
                    quelle: null,
                    status: 0,
                    straße: null,
                    telefon: null,
                    titel: null,
                    vorname: null
                }
            }
        },
        computed: {
            ...mapState(['personen'])
        },
        mounted() {
        },
        props: ['aktionen', 'firmen', 'laender', 'kategorien', 'newPersonDialog'],
        methods: {
                   formateDate: function (dateString) {
                var date = new Date(dateString);

                var day = date.getDate().toString();
                day = day.length == 1 ? "0" + day : day;

                var month = (date.getMonth() + 1).toString();
                month = month.length == 1 ? "0" + month : month;

                var year = date.getFullYear().toString();
                return day + '.' + month + '.' + year;

            },
            save(date) {
                this.$refs.menu.save(date)
            },
            createNewPerson: function () {
                if (this.$refs.form.validate()) {

                    this.$http.post('/api/Stammdaten/CreatePerson', this.person).then(response => {
                        var newPerson = response.data;


                        this.personen.push(JSON.parse(JSON.stringify(newPerson)));
                        this.$refs.form.reset();
                        this.$emit('update:newPersonDialog', false);

                    }).catch((error) => console.log(error));
                }
            },
            createNewPerson2: function () {
                var person = {
                    anrede: 'Herr', bemerkung: 'test-btn', created: '2018-10-10', email: null, firma: {id: 1}, geburtstag: '2018-10-10', kontaktVon: 'nza', land: null, mobil: null, nachname: 'test', namenZusatz: null, ort: 'Essen', plz: null, position: 'Tester', quelle: null, status: 0, straße: null, telefon: null, titel: null, vorname: 'Tester-Btn'}

                    this.$http.post('/api/Stammdaten/CreatePerson', person).then(response => {
                        this.$refs.newUserForm.reset()
                        this.personen.push(this.person);

                        // Dialog schließen!

                    }).catch((error) => console.log(error));
            },
            //ShowNewKontakt: function () {
            //    if (this.newKontakt) {
            //        this.newKontakt = false;
            //    }
            //    else {
            //        this.newKontakt = true;
            //    }
            //}
        }
    }
</script>

<style scoped>
</style>
